import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import SiteMeta from '../components/meta-data'
import Helmet from 'react-helmet'


class PolicyTemplate extends React.Component{

    constructor(props) {
        super(props)
    }

    render() {
        const policy = get(this, 'props.data.contentfulPolicy')
        //const metaData = get(this.props, 'data.site.siteMetadata.homePage')
        const data = {
            hero: 'hero-home',
            size: 'is-medium',
            isHidden: true,
            text: policy.title,
        }
        return(
            <Layout location={this.props.location} data={data}>
                {/* <SiteMeta metaData={metaData}/> */}
                <Helmet>
                    <html lang="en"/>
                    <title>{policy.title} | Red Digital China</title>
                    <meta name="description" content={`Read our ${policy.title}`}/>
                    <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="79580515-4c86-4e39-aa84-3d661091d2e0" type="text/javascript" async></script>
                </Helmet>
                <section className="section">
                    <div className="container">
                        <div className="content is-large is-size-5-mobile"
                            dangerouslySetInnerHTML={{
                            __html: policy.content.childMarkdownRemark.html,
                            }}
                        /> 
                    </div>
                </section>        
            </Layout>    
        )
    }

}

export default PolicyTemplate

export const pageQuery = graphql`
    query PolicyBySlug($slug: String!){
        site {
            siteMetadata {
              homePage {
                title
                description
                imageUrl
                siteUrl
              }
            }
        } 
        contentfulPolicy(slug: {eq: $slug}) {
            title
            slug
            content {
              childMarkdownRemark {
                html
              }
            }
          } 
    }

`